$black: #171717;
$white: #fff;
$grey: #242424;
$colorPrimaryDark: #0f0f0f;
$colorPrimary: #1f1f1f;
$colorPrimaryLight: #262626;
$accent: #ffc840;

$wide: 1200px;
$laptop: 1024px;
$tablet: 768px;
$phone: 320px;
// $phone: 320px;
